<template>
  <div class="body_bgi full bgs_full">
    <big-screen-header headerName="巴林右旗动物免疫疫苗详情" />

    <div class="content_box w_full h_full-50 flex_row_around p_10 border_box">
      <!-- 1 -->
      <div class="w_32_3_p h_full flex_column_between">
        <div class="w_full h_full overflow_y_auto p_10 border_box bgs_full chart_box_bgi_1 scroll_box">
          <div class="text_1 jian_bian_text_1 fw_bold pb_10">疫苗信息</div>

          <div class="w_full h_90_p scroll_box overflow_y_auto scroll_box">
            <div class="w_full p_10 cursor text_1 color_fff flex_row_start">
              <div class="w_full flex_row_start flex_wrap">
                <div class="w_50_p p_5">
                  <span class="color_fff">疫苗名称：</span>
                  <span class="theme_color_green">{{ currentYiMiao.name }}</span>
                </div>

                <div class="w_50_p p_5">
                  <span class="color_fff">免疫病种：</span>
                  <span class="main_color">{{ currentYiMiao.effect }}</span>
                </div>

                <!-- <div class="w_50_p p_5">
                  <span class="color_fff">存放温度：</span>
                  <span class="main_color">{{ currentYiMiao.xxx }}</span>
                </div> -->

                <div class="w_50_p p_5">
                  <span class="color_fff">疫苗用量：</span>
                  <span class="main_color font_number">{{ currentYiMiao.specs }}</span>
                </div>

                <div class="w_50_p p_5">
                  <span class="color_fff">存放温度：</span>
                  <span class="main_color font_number">{{ currentYiMiao.temperature || '10℃' }}</span>
                </div>

                <div class="w_full p_5">
                  <span class="color_fff">疫苗描述：</span>
                  <span class="main_color">{{ currentYiMiao.description }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- <div class="w_full h_49_p overflow_y_auto p_10 border_box bgs_full chart_box_bgi_1">
          <echart title="免疫统计" chartType="饼图" :chartData="data3" />
        </div> -->
      </div> 

      <!-- 2 -->
      <div class="w_32_3_p h_full flex_column_between">
        <div class="w_full h_49_p overflow_y_auto p_10 border_box bgs_full chart_box_bgi_1 relative">
          <div class="text_1 jian_bian_text_1 fw_bold pb_10">疫苗图片</div>

          <div class="w_full h_full-40" v-if="currentYiMiao.picture">
            <img class="full border_radius_10 response_img" :src="filePath + currentYiMiao.picture" alt="">
          </div>

          <span class="main_color absolute_center text_1" v-else>暂无疫苗图片</span>
        </div>

        <div class="w_full h_49_p overflow_y_auto p_10 border_box bgs_full chart_box_bgi_1 scroll_box">
          <div class="full relative">
            <!-- <div class="w_full cursor text_1 color_fff flex_row_between absolute top_30">
              <div class="w_50_p">
                <span class="theme_color_green">全旗库存 : </span> 
                <span class="font_number fw_bold ls_3 text_1">20000</span>
                <span class="theme_color_green">毫升</span> 
              </div>
            </div> -->

            <echart 
              title="疫苗库存" 
              chartType="环状图" 
              bgi="none" 
              :chartData="data1" 
              @emitChartParams="handleClickTownName" 
            />
          </div>
        </div>
      </div> 

      <!-- 3 -->
      <div class="w_32_3_p h_full flex_column_between">
        <div class="w_full h_49_p bgs_full chart_box_bgi_1 p_10">
          <div class="text_1 fw_bold jian_bian_text_1">疫苗入库记录</div>
          <div class="w_full h_90_p flex_column_start">
            <div class="w_full h_40 p_10 cursor text_1 color_fff fw_bold flex_row_start sticky top_10">
              <div class="w_full flex_row_start flex_wrap">
                <div class="flex_1 font_number text_left">入库时间</div>
                <div class="flex_1 cursor">入库数量（毫升）</div>
                <div class="flex_1 cursor">库存（毫升）</div>
              </div>
            </div>

            <div class="w_full h_full-40 scroll_box overflow_y_auto">
              <div 
                class="w_full pv_5 cursor text_1 color_fff flex_row_start" 
                :class="{active_color: activeIndex == index}"
                v-for="(item, index) in vaccinumInboundList" :key="index"
              >
                <div class="w_full flex_row_start flex_wrap">
                  <div class="flex_1 font_number text_left">{{ formatDate(item.createTime) }}</div>
                  <div class="flex_1 font_number text_left">{{ item.changeNum }}</div>
                  <div class="flex_1 font_number text_left">{{ item.amount }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="w_full h_49_p bgs_full chart_box_bgi_1 p_10">
          <div class="text_1 fw_bold jian_bian_text_1">疫苗出库记录</div>
          <div class="w_full h_90_p scroll_box overflow_y_auto auto_scroll_y1">
            <div class="w_full h_40 p_10 cursor text_1 color_fff fw_bold flex_row_start sticky top_10">
              <div class="w_full flex_row_start flex_wrap">
                <div class="flex_1 font_number text_left">出库时间</div>
                <div class="flex_1 cursor">出库数量（毫升）</div>
                <div class="flex_1 cursor">库存（毫升）</div>
              </div>
            </div>

            <div class="w_full h_full-40 scroll_box overflow_y_auto">
              <div 
                class="w_full pv_5 cursor text_1 color_fff flex_row_start" 
                :class="{active_color: activeIndex == index}"
                v-for="(item, index) in vaccinumOutboundList" :key="index"
              >
                <div class="w_full flex_row_start flex_wrap">
                  <div class="flex_1 font_number text_left">{{ formatDate(item.createTime) }}</div>
                  <div class="flex_1 font_number text_left">{{ item.changeNum }}</div>
                  <div class="flex_1 font_number text_left">{{ item.amount }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> 
    </div>
  </div>
</template>

<script>
  import defMixin from '@/mixins/def.js' 
  import componentsMixin from '@/mixins/components.js'
  import geoJsonMapMixin from '@/mixins/geoJson-map.js'
  import imgMixin from '@/mixins/def-img.js'

  import { filePath } from '@/const.js'

  import { getVaccinumTree } from '@/api/fang-yi/fang-yi.js'
  import { 
    getCountyVaccinumStatistics, 
    getVaccinumStatistics, 
    getSiteVaccinumRecordList,
    getInventoryChangeLog 
  } from '@/api/fang-yi/yi-miao.js'

  export default {
    name: 'YiMiaoDetailPage',
    mixins: [defMixin, componentsMixin, geoJsonMapMixin, imgMixin],
    data() {
      return {
        storeId: 1,
        filePath: filePath,

        siteList: [],

        currentYiMiao: {},
        yiMiao: [],
        data1: {},
        data2: {
          waitTime: 3000,
          header: ['时间', '地区', '数量（毫升）'],
          rowNum: 8,
          headerBGC: "",    // #226acc
          headerHeight: 30, 
          oddRowBGC: "", 
          evenRowBGC: "",
          data: [
            ['2023-10-14', '大板镇', '5000'],
            ['2023-10-14', '索博日嘎镇', '6000'],
            ['2023-10-14', '宝日勿苏镇', '8000'],
            ['2023-10-14', '查干诺尔镇', '6000'],
            ['2023-10-14', '巴彦琥硕镇', '5000'],
            ['2023-10-14', '西拉沐沦苏木乡', '4000'],
            ['2023-10-14', '巴彦塔拉苏木乡', '6000'],
            ['2023-10-14', '幸福之路苏木乡', '7000'],
            ['2023-10-14', '查干沐沦苏木', '4000'],
          ]
        },
        data3: {},
        vaccinumInboundList: [],
        vaccinumOutboundList: []
      }
    },

    mounted() {
      this.initChart()

      let vaccinumId = this.$route.params.id
      let yiMiaoName = this.$route.params.name
      let typeId = this.$route.params.typeId

      this.getYiMiaoDetail(yiMiaoName)

      // 获取 巴林右旗动防站 疫苗 统计
      this.getCountyVaccinumStatistics(vaccinumId)

      // 获取 疫苗免疫统计
      this.getVaccinumStatistics(vaccinumId)

      // 获取 xx疫苗的 【中心仓库】 入库记录
      this.getInventoryInBountList(this.storeId, vaccinumId)
      // 获取 xx疫苗的 【中心仓库】 出库记录
      this.getInventoryOutBountList(this.storeId, vaccinumId)
    },

    methods: {
      initChart() {
        this.data1 = {
          unit: '毫升',
          data: [
            {id: '1', name: "巴彦琥硕动防站", headId: '19', value: 1460, unit: ''},
            {id: '2', name: "大板镇动防站", headId: '20', value: 2738, unit: ''},
            {id: '3', name: "巴彦塔拉动防站", headId: '21', value: 1436, unit: ''},
            {id: '4', name: "西拉沐沦动防站", headId: '22', value: 1352, unit: ''},
            {id: '5', name: "幸福之路动防站", headId: '23', value: 852, unit: ''},
            {id: '6', name: "宝日勿苏动防站", headId: '24', value: 852, unit: ''},
            {id: '7', name: "查干诺尔动防站", headId: '25', value: 852, unit: ''},
            {id: '8', name: "查干沐沦动防站", headId: '26', value: 852, unit: ''},
            {id: '9', name: "索博力嘎动防站", headId: '27', value: 852, unit: ''},
            {id: '10', name: "赛罕动防站", headId: '28', value: 852, unit: ''},
            {id: '11', name: "达尔罕动防站", headId: '29', value: 852, unit: ''}
          ]
        }
      },

      // 获取 巴林右旗动防站 疫苗 统计
      async getCountyVaccinumStatistics(id) {
        let res = await getCountyVaccinumStatistics(null, id);
        try {
          if (res.code == 1000) {
            // debugger
          }
        }
        catch (err) {
          console.log(err)
        }
      },
      
      // 获取 xxx 疫苗的疫苗统计
      async getVaccinumStatistics(id) {
        let res = await getVaccinumStatistics(id);
        try {
          if (res.code == 1000) {
            this.data3.data = this.formatYiMiaoList(res.data)
          }
        }
        catch (err) {
          console.log(err)
        }
      },

      // 获取疫苗的 入库信息
      async getInventoryInBountList(storeId, goodsId) {
        // const CHANGETYPE_INITIAL: number = 0; // 库存登记-入库 (中心 入库)
        // const CHANGETYPE_RECIEVE_FROM: number = 1; // 领取上级给的-入库 （动防站 、防疫员）
        // const CHANGETYPE_RETURN_FROM: number = 2;  // 接收下级退还-入库 （中心、动防站）

        // const CHANGETYPE_DISTRIBUTE_TO: number = -1;   // 发放给下级-出库 （中心、动防站）
        // const CHANGETYPE_RETURN_TO: number = -2;   // 退还给上级-出库 （动防站 、防疫员）

        let params = {
          storeId,
          goodsId,
          changeType: 1,
          page: 1,
          size: 1000
        }
        let res = await getInventoryChangeLog(params);
        try {
          if (res.code == 1000) {
            this.vaccinumInboundList = res.data.list
          }
        }
        catch (err) {
          console.log(err)
        }
      },

      // 获取疫苗的 出库信息
      async getInventoryOutBountList(storeId, goodsId) {
        // const CHANGETYPE_INITIAL: number = 0; // 库存登记-入库 (中心 入库)
        // const CHANGETYPE_RECIEVE_FROM: number = 1; // 领取上级给的-入库 （动防站 、防疫员）
        // const CHANGETYPE_RETURN_FROM: number = 2;  // 接收下级退还-入库 （中心、动防站）

        // const CHANGETYPE_DISTRIBUTE_TO: number = -1;   // 发放给下级-出库 （中心、动防站）
        // const CHANGETYPE_RETURN_TO: number = -2;   // 退还给上级-出库 （动防站 、防疫员）

        let params = {
          storeId,
          goodsId,
          changeType: -1,
          page: 1,
          size: 1000
        }
        let res = await getInventoryChangeLog(params);
        try {
          if (res.code == 1000) {
            this.vaccinumOutboundList = res.data.list
          }
        }
        catch (err) {
          console.log(err)
        }
      },

      // 格式化 图表所用的 数据
      formatYiMiaoList(list) {
        let newList = []
        let item = {}

        for (let i in list) {
          item = { 
            id: list[i].vaccinumId,
            name: list[i].vaccinumName,
            value: list[i].totalCount,
            unit: list[i].unit,
          }
          newList.push(item)
        }

        return newList
      },

      // 获取 动防站 => xxx 疫苗的 疫苗入库列表
      async getSiteVaccinumRecordList(siteId, vaccinumId) {
        let res = await getSiteVaccinumRecordList(siteId, vaccinumId)
        try {
          if (res.code == 1000) {
            this.currentYiMiao = res.data[0]
            this.ruKuList = res.data
          }
        } catch(err) {
          console.log(err)
        }
      },

      // 获取 单个疫苗
      async getYiMiaoDetail(name) {
        let res = await getVaccinumTree(name);
        try {
          if (res.code == 1000) {
            console.log('res.data : ', res.data);
            this.currentYiMiao = res.data[0].children[0]

            // console.log('this.currentYiMiao : ', this.currentYiMiao);
          }
        }
        catch (err) {
          console.log(err)
        }
      },

      clickDataItem(e) {
        // console.log('点击 DataV item : ', e);

        let name = e.ceil

        let rowIndex = e.rowIndex
        let columnIndex = e.columnIndex
        let row = e.row
      },

      // 点击 区域名称
      handleClickTownName(val) {
        this.$router.push(`/zhanDianPage/${val.id}/${val.name}/${val.headId}`)
      },

    }
  }
</script>

<style>
.color_4de69c { color: #77f5c4 !important; }
</style>